import React, {useState, useMemo, useEffect, useCallback} from 'react';
import style from './Login.module.css';
import Background from "../Background";
import api from '../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faClose, faCoffee, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import myDeskLogo from '../../style/img/myDesk_Logo_white.png';

export default function Login({onLogin}) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  
  const [pswVisible, setPswVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    let c = localStorage.getItem('company');
    setCompany(c);
    api.get('/mobile/check').then(res => {
      if (res.data.isLogged) {
        onLogin(res.data);
      }
    });
  }, []);

  const login = () => {
    api.post('/mobile/login', {
      username: userName,
      password,
      company
    }).then(res => {
      onLogin(res.data);
      localStorage.setItem('company', company);
      setErrorMessage('')
    }).catch(err => {
      if (err.response?.data) {
        setErrorMessage(err.response.data.message)
      }
    });
  }

  const keyDownSubmit = e => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const togglePswVisibility = () => {
    setPswVisible((prev) => !prev)
  }

  return(
      <Background type="login">
        <div className={style.wrapper}>
          <div className="row flex-column">
            <div className="col-12 text-center pb-5">
              <img src={myDeskLogo} alt="" className={style.logo}/>
            </div>
            <div className="col-12">
              <div className="box-login bg-white d-flex flex-column">
                {errorMessage ? 
                <div className='d-flex align-items-center mb-3'>
                  <FontAwesomeIcon icon={faClose} className="text-danger" size={"1x"}/>
                  <span className="text-danger ms-2">{errorMessage}</span> 
                </div>
                : null}
                <label className={style.label}>Username</label>
                <input className={`${style.input} mb-3`} value={userName} onChange={e => setUserName(e.target.value)}/>
                <label className={style.label}>Password</label>
                <div className='w-100 mb-3 position-relative'>
                  <input type={pswVisible ? 'text' : 'password'} className={`${style.input} w-100`} value={password} onChange={e => setPassword(e.target.value)} onKeyDown={keyDownSubmit}/>
                  <FontAwesomeIcon icon={pswVisible ? faEyeSlash : faEye} className={`text-gray ${style.iconEye}`} onClick={togglePswVisibility}/>
                </div>
                <label className={style.label}>Company</label>
                <input className={`${style.input} mb-3`} value={company} onChange={e => setCompany(e.target.value)} onKeyDown={keyDownSubmit}/>
                <button className={`${style.button} p-3`} onClick={login}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span></span>
                    <span>Login</span>
                    <FontAwesomeIcon icon={faArrowRight} className="" size={"1x"}/>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Background>
  )

}
