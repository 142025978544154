import React, {useCallback, useState, useEffect, useContext} from 'react';
import { Modal, Button, Row, Col} from "react-bootstrap"
import api from '../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'

export const ModalNotifications = ({setShow}) => {

    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        api.get('/mobile/getNotifications').then((res) => {
            setNotifications(res.data)
        }).catch((err) => {
            console.log(err)
          })
    },[])
    
    console.log(notifications)
    return(
        <div>
            <div className="overlay"></div>
            <div className='custom-modal' style={{ overflow:'hidden', top:0}}>
                <h5 className='fw-bold'>Notifiche</h5>
                <hr />
                <Row id="notifications">
                    <Col sm={12} style={{height:'60vh', overflowY:'scroll'}}>
                        {notifications.map((el) => 
                            <div className="card p-3 mb-3">
                                <div className="d-flex align-items-start">
                                    <FontAwesomeIcon icon={faBell} size={'1'} className='text-warning me-3 '/>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <h6 className='mb-0 fw-bold'>{el.title}</h6>
                                            <p className='mb-0'>{el.body}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <span className="small text-right mt-2" style={{fontSize:12}}>{moment(new Date(el.date_ins)).format("DD/MM/YYYY")} alle {moment(new Date(el.date_ins)).format("HH:mm")}</span>
                                </div>
                            </div>
                        )}
                    </Col>
                    <hr />
                    <div className="d-flex justify-content-center align-items-center">
                        <Button 
                            variant="secondary" 
                            onClick={() => setShow(false)}
                            className="me-2"
                            >
                            Chiudi
                        </Button>
                    </div>
                </Row>
            </div>
        </div>
    )
}