import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken, isSupported} from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";

const firebaseConfig = {
  apiKey: "AIzaSyAYQrH1BytQx7NOY0mO-rYvIA6D6ocYAvY",
  authDomain: "mydesk-mobile-pwa.firebaseapp.com",
  projectId: "mydesk-mobile-pwa",
  storageBucket: "mydesk-mobile-pwa.appspot.com",
  messagingSenderId: "552697130892",
  appId: "1:552697130892:web:d2e4cbc83bf7badec0f0a9"
  };
const messagingKey = 'BO1Cs98-_HuSarY5hfw5PtB_x6v0-432WOiwJ2flfVyXMEoIVgKf_KlnJYnAL6xbkBAYBiqk9QfzVRsBXbLn4eM'
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const deleteTokenFCM = () => {
  isSupported().then((state) => {
    if(state){
      deleteToken(messaging)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getTokenFCM = async () => {
  return new Promise((resolve, reject) => {
    isSupported().then((state) => {
      // alert('firebase supported is ' + state)
      if(state){
        getToken(messaging, {vapidKey: messagingKey}).then((currentToken) => {
          if (currentToken) {
            console.log('current token for client: ', currentToken);
            resolve({state:true, token:currentToken});
          } else {
            console.log('No registration token available. Request permission to generate one.');
            reject({state:false, token:null});
          }
        }).catch((err) => {
          reject({state:false, token:null})
          console.log('An error occurred while retrieving token. ', err);
        });
      }else{
        resolve({state:false, token:null});
      }
    }).catch((err) => {
      // alert(err)
      reject({state:false, token:null})
    })
  })
  }


  //Check permissions
  export const askNotificationPermission = async () => {
    if (!("Notification" in window)) {
      console.log("Questo browser non supporta notifiche");
    }else if (Notification.permission != "granted") {
      Notification.requestPermission().then((permission) => {
        if(permission != "denied"){
          window.location.reload()
        }
        console.log('Il permesso ora è: ' + permission)
      });
    }else{
      console.log('notification not available')
    }
  }


export const onMessageListener = () =>{
  return new Promise((resolve) => {
    isSupported().then((state) => {
      if(state){
        onMessage(messaging, (payload) => {
          resolve(payload);
        });
      }else{
        resolve(null)
      }
    }).catch((err) => {
      console.log(err)
    })
  });
}
