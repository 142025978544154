import React from 'react'
import Toast from 'react-bootstrap/Toast';

export const Notification = ({data, setData}) => {

    return(
        <Toast style={{position:'fixed', right:0, left:0, width:'100%', zIndex:99}} show={data.state} onClose={() => setData((prev) => ({...prev, state:false}))}>
            <Toast.Header>
                <strong className="mr-auto">Notifica</strong>
            </Toast.Header>
            <Toast.Body style={{background:'white'}}>{data.message}</Toast.Body>
        </Toast>
    )
}