import React, {useContext, useState} from 'react';
import style from './TaskBar.module.css';
import AuthContext from "../../context/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowLeft, faArrowLeftRotate, faBars, faBell, faBellSlash, faClose, faLongArrowLeft } from '@fortawesome/free-solid-svg-icons';
import arrowLeft from '../../style/img/ic_back.png'
import Badge from 'react-bootstrap/esm/Badge';
import { ModalConfirmAction } from '../Modal/ModalConfirmAction';
import { ModalNotifications } from '../Modal/ModalNotifications';

export default function TaskBar ({user, plugin, closePlugin, toggleMenu, companyCode, initialLetters}) {
  const {fullName, t} = useContext(AuthContext);
  const [date, setDate] = useState(('0' + new Date().getHours()).slice(-2) + ':' + ('0' + new Date().getMinutes()).slice(-2))
  const [confirmModal, setConfirmModal] = useState({
    show:false,
    title:'',
    message:'',
    action:()=>{}
  })
  const [modalNotifications, setModalNotifications] = useState(false)

    return(<>
          {confirmModal.show ? <ModalConfirmAction data={confirmModal} setData={setConfirmModal}/> : null} 
          {modalNotifications ? <ModalNotifications setShow={setModalNotifications}/> : null} 
          <div className={`${style.desktopBar} d-flex justify-content-between align-items-center`}>
            {plugin ? 
              <>
                    <div className={'text-white fw-bold'}>{plugin.name}</div>
                    <div className='d-flex align-items-center'>
                      {/* <FontAwesomeIcon icon={Notification.permission == 'granted' ? faBell : faBellSlash} size={'1x'} className='text-warning me-3 h5 mb-0' onClick={() => setModalNotifications(true)}/> */}
                      <button className="btn btn-danger d-flex align-items-center" 
                        onClick={() => setConfirmModal({
                            show:true,
                            title:t?.closeapp_modal_title,
                            message:t?.closeapp_modal_body,
                            action:() => closePlugin()
                            })}>
                        {t?.close}
                      </button>
                    </div>
                   
              </>
              :
              <>
                <FontAwesomeIcon icon={faBars} className="text-white cursor-pointer" size={"2x"} onClick={toggleMenu}/>
                  <div className="d-flex align-items-center justify-content-between text-white">
                    {/* <FontAwesomeIcon icon={Notification.permission == 'granted' ? faBell : faBellSlash} size={'1x'} className='text-warning me-3 h5 mb-0' onClick={() => setModalNotifications(true)}/> */}
                    <Badge className={`${style.badgeWhite} me-3`}>{companyCode}</Badge>
                    <div className={style.circleWhite}>
                      <span className='fw-bold mt-1'>{initialLetters}</span>
                    </div>
                  </div>
              </>
            }
          </div>
          </>
          )
  }


