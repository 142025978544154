import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import Login from "./components/Login/Login";
import Desktop from "./components/Desktop/Desktop";
import AuthContext from "./context/AuthContext";
import api from './utils/api';
import './App.css';
import {uuidv4} from './utils/uuid';
import './utils/firebase.js'
// import { askNotificationPermission, deleteTokenFCM, getTokenFCM, onMessageListener } from './utils/firebase.js';
import { Notification } from './components/Notification/Notification';

const loggedOutUser = {
  isAuth: false,
  fullName: '',
  jwt:'',
  language:'it'
}

function getWSAddress (location) {
  let addr = "";
  if (location.hostname === "www.my-desk.ch" || location.hostname === "prod.my-desk.ch") {
    addr = "wss://wsprod.my-desk.ch:8080";
    //console.log("produzione");
  } else if (location.hostname === "newpreprod.my-desk.ch" || location.hostname === "preprod-mydesk.my-desk.ch") {
    addr = "wss://websocketpreprod.my-desk.ch:8080";
    console.log("pre-produzione");
  } else if (location.hostname === "test.my-desk.ch") {
    addr = "wss://ws.my-desk.ch:8080/";
    console.log("test");
  } else {
    addr = "wss://ws.my-desk.ch:8080/";
    console.log("localhost");
  }
  return addr;
}
function App() {
  const [user, setUser] = useState(loggedOutUser);
  const [plugins, setPlugins] = useState([]);
  const ws = useRef(null);
  const uuid = useRef(uuidv4());
  const [FCMToken, setFCMToken] = useState({state:false, token:null});
  const [notification, setNotification] = useState({
    state:false,
    title:'',
    message:''
  })

  // const retrieveToken = async (id_user) => {
  //   try {
  //     // await askNotificationPermission()
  //     let data = await getTokenFCM()
  //     setFCMToken(data)
  //     if(data.state){
  //       api.post('/mobile/saveFCMToken', {token:data.token, id_user}).then(res => {
  //         console.log(res.data)
  //       }).catch((err) => {
  //         console.log(err)
  //       })
  //     }
  //   }catch(data){
  //     if(!data.state){
  //       setFCMToken(data)
  //     }
  //   }
  // }


  const onLogin = useCallback(data => {
    setUser({
      isAuth: true,
      username: data.username,
      userId: parseInt(data.userId),
      fullName: data.userFullName,
      companyCode: data.companyCode,
      companyId: parseInt(data.companyId),
      jwt:data.jwt,
      language:data.language
    });
    setPlugins(data.Plugins);
    // retrieveToken(data.userId)
    ws.current.send(JSON.stringify({
      message: 'login',
      uuid,
      user: data.username,
      company: data.companyCode,
      companyId: data.companyId,
      userId: data.userId,
      envtype: 'mobile'
    }))
  }, []);

  const logout = useCallback( async () => {
    try{
      // let data = await getTokenFCM()
      // if(data.state){
      //   api.post('/mobile/deleteFCMToken', {
      //     token:data.token, id_user:user.userId
      //   }).catch((err) => {
      //     console.log(err)
      //   }).finally(() => {
          api.get('/mobile/logout').then(res => {
            setUser(loggedOutUser);
            ws.current.send(JSON.stringify({
              message: 'logout',
              user: user.username,
              company: user.companyCode,
              companyId: user.companyId,
              userId: user.userId,
              envtype: 'mobile'
            }))
            // deleteTokenFCM()
          })
        // })
      // }
    }catch(err){
      console.log(err)
    }

  }, [user]);


  useEffect(() => {
    const socket = new WebSocket(getWSAddress(window.location));
    socket.onerror = err => console.error(err);

    ws.current = socket;
    return () => socket.close();
  }, []);

  useEffect(() => {
    const onFrameMessage = e => {
      if (e.data === "token") {
        e.source.postMessage("Token: " + user.jwt, e.origin);
      }else if(e.data === 'cookie') {
        e.source.postMessage("cookie: "+document.cookie, e.origin);
      }
    }
    window.addEventListener("message", onFrameMessage);
    return () => window.removeEventListener("message", onFrameMessage);
  }, [user.jwt]);

  useEffect(() => {
    if(ws.current) {
      ws.current.onmessage = raw => {
        let data = JSON.parse(raw.data);
        //console.log('message', data, user);
        if(data.message === 'login') {
          if(data.uuid !== user.uuid && parseInt(data.companyId) === user.companyId && parseInt(data.userId) === user.userId && data.user===user.username && data.envtype==='mobile') {
            console.log('logout');
            logout();
          }
        }
      }
    }
  }, [logout, user])

  // useEffect(() => {
  //   onMessageListener().then(payload => {
  //     console.log(payload)
  //     setNotification({
  //       state:true,
  //       title:'Notifica Mydesk Mobile',
  //       message:payload.notification.title + ' , ' + payload.notification.body
  //     })
  //   }).catch(err =>
  //     console.log('failed: ', err)
  //   );
  // },[])

  useEffect(() => {
    // askNotificationPermission()
  },[])

  const [t, setT] = useState({})

  useEffect(() => {
    if(user.language){
      setT(require(`./translations/${user.language}.json`))
    }
  },[user.language])

  const context = useMemo(() => ({
    ...user,
    logout,
    t
  }), [user, logout, t]);

  return (
    <div>
      <Notification data={notification} setData={setNotification}/>
      <AuthContext.Provider value={context}>
        {user.isAuth ? <Desktop plugins={plugins} /> : <Login onLogin={onLogin}/>}
      </AuthContext.Provider>
    </div>
  );
}

export default App;
