import React from 'react';
import style from './Plugin.module.css';

const pattern = /^http(s?):\/\/(.+)/g;

export default function Plugin ({plugin}) {
  let src;
 

  if (pattern.test(plugin.url)) {
    src = plugin.url
  } else {
    src = '/plugins/' + plugin.url;
  }
  // src ="https://www.apge.com/";

  return (
    <iframe className={style.frame} src={src} />
  )
}
