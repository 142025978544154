import React from 'react';
import style from './Icon.module.css';


export default function Icon({plugin, onClick}) {

  let img = {};
  if(plugin.icon!=='') {
    img.backgroundImage = `url(/../plugins/${plugin.icon})`;
  }
  return (
    <div className={style.wrapper} onClick={() => onClick(plugin)}>
      <div className={style.icon} style={img}/>
      <div className={style.label}>{plugin.name}</div>
    </div>
  )
}
