import React, {useCallback, useState, useEffect, useContext} from 'react';
import { Modal, Button} from "react-bootstrap"
import AuthContext from '../../context/AuthContext';

export const ModalConfirmAction = ({data, setData}) => {
    const {t} = useContext(AuthContext)

    return(
        <div>
            <div className="overlay"></div>
            <div className='custom-modal'>
                <h5 className='fw-bold'>{data.title}</h5>
                <hr />
                <p>{data.message}</p>
                <hr />
                <div className="d-flex justify-content-start align-items-center">
                <Button 
                    variant="secondary" 
                    onClick={() => setData((prev) => ({...prev, show:false}))}
                    className="me-2"
                    >
                    {t?.cancel}
                </Button>
                <Button 
                    className='bg-blue'
                    variant="primary" 
                    onClick={() => {
                        data.action()
                        setData((prev) => ({...prev, show:false}))
                    }}>
                    {t?.continue}
                </Button>
                </div>
            </div>
        </div>
    )
}