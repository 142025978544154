import React, {useState, useCallback, useContext, useMemo} from 'react';
import TaskBar from "../TaskBar/TaskBar";
import Background from "../Background";
import Icon from "../Icon/Icon";
import SideMenu from "../SideMenu/SideMenu";
import style from './Desktop.module.css';
import Plugin from "../Plugin/Plugin";
import AuthContext from '../../context/AuthContext';
import Badge from 'react-bootstrap/Badge';

export default function Desktop ({plugins}) {
  const [open, setOpen] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const {fullName, companyCode} = useContext(AuthContext);

  const openPluginById = useCallback(id => {
    const plug = plugins.find(p => p.id==id);
    setOpen(plug);
    setShowMenu(false);
  }, [plugins]);

  const initialLetters = useMemo(() => {
    let names = fullName.split(' ')
    let initials = ''
    names.forEach((name) => {
      if(name){
        initials += name[0].toUpperCase()
      }
    })
    return initials
  },[fullName])

  return (
    <>
      <Background type="desktop">
        <TaskBar 
          plugin={open} 
          closePlugin={() => setOpen(null)} 
          toggleMenu={() => setShowMenu(o => !o)} 
          companyCode={companyCode}
          initialLetters={initialLetters}
        />
        {open ? <Plugin plugin={open} /> : (
          <div className={style.wrapper}>
            <div className={style.iconContainer}>
              {plugins.map(plugin => {
                if(plugin.icon_mobile_visible>0) {
                  return <Icon key={plugin.id} plugin={plugin} onClick={setOpen} />
                } else {
                  return null;
                }
              })}
            </div>
            <div style={{height:50}}></div>
          </div>
          )}
        <SideMenu isOpen={showMenu} close={() => setShowMenu(false)} openById={openPluginById} />
      </Background>
    </>
  )

}
