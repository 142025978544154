import React from 'react';
import style from '../style/Background.module.css';
import bgLogin from '../style/img/background-login.png';
import bgMain from '../style/img/background-default.png';


export default function Background ({type, img, children}) {

  const bgStyle = type == 'desktop' ? style.backgroundDesktop : style.backgroundLogin

  return (
      <div className={`${bgStyle} container-fluid `}>
        {children}
      </div>
  )
}
