import React, {useContext} from 'react';
import style from './SideMenu.module.css';
import AuthContext from "../../context/AuthContext";
import imgIcon from "../../style/img/D_white.png";

export default function SideMenu({isOpen, close, openById}) {
  
  const {logout, t} = useContext(AuthContext)
  let classes = style.menu;
  if(isOpen) {
    classes += ' '+style.show;
  }

  const confirmLogout = () => {
    if(window.confirm('Sei sicuro di voler effettuare il logout?')){
      logout()
    }
  }

  return (
    <>
      <div className={classes}>
        <div className={`${style.header}`}>
          <img className={style.headerIcon} src={imgIcon} />
        </div>
        <hr className='text-white'/>
        <div className={style.group}>
          <Option label={t?.change_password} onClick={() => openById(2)} />
          <Option label={t?.options} onClick={() => openById(118)} />
          <Option label={t?.logout} onClick={confirmLogout} />
        </div>
      </div>
      {isOpen ? <div className={style.backdrop} onClick={close} /> : null}
    </>
  )
}


const Option = ({img, label, onClick}) => {
  return (
    <div className={style.option} onClick={onClick??null}>
      {img ? <img className={style.icon} src={img} /> : null }
      <h5 className={style.label}>{label}</h5>
    </div>
  )
}
